.project-section {
  padding: 50px 0;
}

.project-heading {
  font-size: 2.5em;
  margin-bottom: 40px;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.project-item {
  background-color: rgba(255, 255, 255, 0.1); 
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); 
  transition: transform 0.3s ease;
}

.project-item:hover {
  transform: translateY(-10px); 
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.3);
}

.project-item h2 {
  font-size: 1.75em;
  color: #fff;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.project-item .tech-stack {
  font-size: 1em;
  font-style: italic;
  color: #ddd;
  margin-bottom: 15px;
}

.project-item ul {
  list-style-type: disc;
  margin-left: 20px;
}

.project-item ul li {
  color: #fff;
  margin-bottom: 10px;
  line-height: 1.6;
}
