.experience-section {
    padding: 60px 0;
  }
  
  .section-heading {
    color: #ffffff;
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    animation: fadeIn 1s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .vertical-timeline-element--work .vertical-timeline-element-content {
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000000; 
    transition: transform 0.3s ease-in-out;
    animation: slideIn 1s ease-in-out;
  }
  
  .vertical-timeline-element--work .vertical-timeline-element-content:hover {
    transform: scale(1.05);
  }
  
  .vertical-timeline-element-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #3e497a;
  }
  
  .vertical-timeline-element-subtitle {
    font-size: 1.2em;
    color: #3e497a;
    margin-bottom: 15px;
  }
  
  .timeline-content {
    color: #333333;
    font-size: 1em;
    line-height: 1.6em;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .timeline-location {
    font-size: 1em;
    font-style: italic;
    color: #555555;
    margin-bottom: 15px;
  }
  
  .vertical-timeline-element-date {
    color: #ffffff !important; 
  }
  
  .vertical-timeline-element-icon {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    background: linear-gradient(135deg, #2f3640, #3e497a);
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  